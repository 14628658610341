import React from "react";
import Snowfall from "react-snowfall";
import "./yearend2022.css";

const YearEnd2022 = () => {
  return (
    <div className="year_end">
      {/* Landing page. */}
      <div className="landing_page">
        <img src="images/happyholidays.jpg" className="happyholidays"></img>
        <Snowfall
          speed={[1.0, 2.0]}
          wind={[-0.5, 0.5]}
          snowflakeCount={50}
          color={"white"}
        />
      </div>
      {/* Letter from us. */}
      <p className="letter_from_us">
        <br />
        Hayley + Eric had quite the year. They made a home in Irvine, planned a
        wedding (and honeymoon) to fruition, and settled in to married life.
        <br />
        <br />
        <br />
        As individuals... <br />
        <br />
        Along with wedding planning, Hayley ramped up on a new team at work, now
        working on the{" "}
        <a
          href="https://developer.android.com/training/wearables/health-services"
          target="_blank"
        >
          Wear Health Services API
        </a>
        . <br />
        <br />
        Eric got promoted to an FP&A manager, had some fun cooking with Blue
        Apron, and put his nose to the grindstone to fix his golf swing.
        <br />
        <br />
        Shamu continues to be a handsome man. He enjoys being the talk of the
        town in his cat stroller.
        <br />
        <br />
        Hayley & Eric are so happy to have had such supportive friends + family
        this year and can't walt to see what 2023 has to offer.
        <br />
        <br />
      </p>
      {/* Photos. */}
      <div className="photos">
        <div className="photocontainer">
          <img src="images/june.jpeg" className="photo"></img>
          <div className="overlay">
            <div className="phototext">Celebrating Hayley's 26th at Mastro's Ocean Club</div>
          </div>
        </div>
        <div className="photocontainer">
          <img src="images/haynric.jpg" className="photo"></img>
          <div className="overlay">
            <div className="phototext">10.22.2022</div>
          </div>
        </div>
        <div className="photocontainer">
          <img src="images/ceremony.jpg" className="photo"></img>
          <div className="overlay">
            <div className="phototext">Hayley accidentally put the ring on Eric's right hand.</div>
          </div>
        </div>
        <div className="photocontainer">
          <img src="images/groupwedding.jpg" className="photo"></img>
          <div className="overlay">
            <div className="phototext">All our people.</div>
          </div>
        </div>
        <div className="photocontainer">
          <img src="images/honeymoon.jpg" className="photo"></img>
          <div className="overlay">
            <div className="phototext">Honeymoonin' in San Jose del Cabo</div>
          </div>
        </div>
        <div className="photocontainer">
          <img src="images/holiday.jpg" className="photo"></img>
          <div className="overlay">
            <div className="phototext">Typical Irvine walk to the Spectrum date night.</div>
          </div>
        </div>
      </div>
      <p className="psst">
        Psst...
        <a href="https://photos.app.goo.gl/cuxFGmFXUfqQEorJ9" target="_blank">
          here
        </a>{" "}
        are our professional wedding photos.
        <br />
      </p>
    </div>
  );
};

export default YearEnd2022;
