import React from 'react';
import { ImageBackground, StyleSheet, Text, View } from "react-native";
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import YearEnd2022 from './pages/yearend2022.js';

function App() {
  return (
    <View>
    <Router>
      <Routes>
      <Route exact path='/' element={<YearEnd2022/>} />
      </Routes>
    </Router>
    </View>
  );
}


export default App;
