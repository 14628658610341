// import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
  
export const Nav = styled.nav`
  align-items: center;
  background: #dbdbdb4f;
  height: 85px;
  display: flex;
  flex-direction: row;
  justify-items: stretch;
  justify-content: space-evenly;
  padding: 0;
  width: 100%;
  z-index: 12;
  font-size: 20px;

  -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.32);
	   -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.32);
	        box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.32);
`;
  
export const NavLink = styled(Link)`
  color: #808080;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
	   -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
	        box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
    // background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32), rgba(255,0,0,0));
    color: rgba(60, 60, 60, 1);
  }

  &:hover {
    -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
	   -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
	        box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
     color: rgba(60, 60, 60, 1);
  }
`;

export const DarkNavLink = styled(Link)`
  color: #808080;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
    -webkit-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
	   -moz-box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
	        box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.6);
    // background-image: linear-gradient(to top, rgba(0, 0, 0, 0.32), rgba(255,0,0,0));
    color: rgba(60, 60, 60, 1);
  }
`;
  
// export const Bars = styled(FaBars)`
//   display: none;
//   color: #808080;
//   @media screen and (max-width: 768px) {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   }
// `;
  
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  height: inherit;
  justify-content: space-evenly;
  margin-right: -24px;
  width: 100%;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavMenuButton = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    height: inherit;
    justify-content: space-evenly;
    margin-right: -24px;
    width: 100%;
    white-space: nowrap;  
   }
   display:none;
`;